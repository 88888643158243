import { Modules } from '@kaetram/common/network';
import Character from '../../../../../../src/entity/character/character';
import { EntityData } from '@kaetram/common/types/entity';
import { Affinities, AffinityMap } from '@kaetram/common/extensions/sot/network/modules';
import Game from '@kaetram/client/src/game';
import log from '@kaetram/common/util/log';
import ConstructLog from '@kaetram/client/extensions/sot/src/entity/constructlog/constructlog';

type ManaPointsCallback = (
    affinity: Affinities,
    manaPoints: number,
    maxManaPoints: number,
    decrease?: boolean
) => void;

type ConstructLogCallback = (constructLog: ConstructLog) => void;

export default class Construct extends Character {
    private manaMap: AffinityMap = { Earth: 0, Fire: 0, Metal: 0, Void: 0, Water: 0, Wood: 0 };
    private maxManaMap: AffinityMap = { Earth: 0, Fire: 0, Metal: 0, Void: 0, Water: 0, Wood: 0 };
    private log: ConstructLog = new ConstructLog();

    private manaPointsCallback?: ManaPointsCallback;
    private constructLogCallback?: ConstructLogCallback;

    public constructor(instance: string, private game: Game) {
        super(instance, Modules.EntityType.Construct);
    }

    public addConstructLogEntry(order: number, description: string, blockType: string) {
        this.log.addLogEntry(order, description, blockType);
        this.constructLogCallback?.(this.log);
    }

    /**
     * Sets the mana points values onto the character.
     * @param manaPoints The new mana points value we are setting.
     * @param maxManaPoints Optional parameter if we wish to update the max mana points.
     */

    public setManaPoints(affinity: Affinities, manaPoints: number, maxManaPoints?: number): void {
        let decrease = false;

        // Clamp the manaPoints to 0.
        if (manaPoints < 0) manaPoints = 0;

        // Use the decrease value for the callback (used for certain UI special effects).
        if (manaPoints < this.manaMap[affinity]) decrease = true;

        this.manaMap[affinity] = manaPoints;

        // Update the max hitPoints if it is specified.
        if (maxManaPoints) this.maxManaMap[affinity] = maxManaPoints;

        // Callback contains the new maxManaPoints if specified, otherwise we use the current one.
        this.manaPointsCallback?.(
            affinity,
            this.manaMap[affinity],
            maxManaPoints || this.maxManaMap[affinity],
            decrease
        );
    }

    refreshHeader() {
        this.setHitPoints(this.hitPoints!, this.maxHitPoints!);
        for (let key in this.manaMap) {
            let affinity = key as Affinities;
            this.setManaPoints(affinity, this.manaMap[affinity], this.maxManaMap[affinity]);
        }
    }

    override despawn(): void {
        this.game.terraGame.removeManifestedConstruct();
        super.despawn();
    }

    /**
     * Callback for when hitpoints undergo a change.
     * @param callback Contains the new hitpoints and the max hitpoints.
     */

    public onManaPoints(callback: ManaPointsCallback): void {
        this.manaPointsCallback = callback;
    }

    /**
     * Callback for when construct log is updated
     * @param callback Contains the new constructlog
     */

    public onConstructLog(callback: ConstructLogCallback): void {
        this.constructLogCallback = callback;
    }

    /**
     * Creates a new construct object, sets data such as its health,
     * attack range, level, etc, and returns that object.
     * @param info The entity data object containing construct information.
     * @returns A new construct object.
     */

    public static createConstruct(info: EntityData, game: Game): Construct {
        let construct = new Construct(info.instance, game);

        if (info.constructInfo) {
            let { mana, maxMana, owner, instance } = info.constructInfo;

            if (owner === game.player.instance) {
                log.debug(`Your construct with instance [${instance}] has been manifested`);
                game.terraGame.setManifestedConstruct(construct as Construct);
            }

            if (mana) {
                for (let key in mana) {
                    let affinity = key as Affinities;
                    let manaPoints = mana[affinity];
                    let maxManaPoints = maxMana?.[affinity];
                    construct.setManaPoints(affinity, manaPoints, maxManaPoints);
                }
            }
        }

        construct.setHitPoints(info.hitPoints!, info.maxHitPoints!);

        // Apply the mob-specific properties
        construct.attackRange = info.attackRange!;
        construct.level = info.level!;
        construct.movementSpeed = info.movementSpeed!;
        construct.orientation = info.orientation!;

        // Set the display info properties directly onto the mob.
        if (info.displayInfo) {
            if (info.displayInfo.colour) construct.nameColour = info.displayInfo.colour;
            if (info.displayInfo.scale) construct.customScale = info.displayInfo.scale;
        }

        return construct;
    }
}
