import _ from 'lodash-es';

import type { ClientPacketHandler } from '@kaetram/common/extensions/sot/types/messages/toclient';

import type App from '@kaetram/client/src/app';
import type Game from '@kaetram/client/src/game';
import { CustomPackets } from '@kaetram/common/extensions/sot/src/enums';
import Packets from '@kaetram/common/network/packets';
import { PACKET_ID_PREFIX } from '@kaetram/common/extensions/sot/src/constants';
import { ServerPacket } from '@kaetram/common/extensions/sot/types/messages/toserver';

type MessageHandler = () => ((...data: never[]) => boolean) | undefined;

export default class Messages {
    private messages: Map<string, MessageHandler>;

    /**
     * Do not clutter up the Socket class with callbacks,
     * have this class here until a better method arises in my head.
     *
     * This class should not have any complex functionality, its main
     * role is to provide organization for packets and increase readability
     *
     * Please respect the order of the Packets Enum and arrange functions
     * accordingly.
     */
    public constructor(private app: App, private game: Game) {
        this.messages = new Map<string, MessageHandler>();

        setInterval(() => {
            this.send({
                type: CustomPackets.Ping
            });
        }, 60_000);
    }

    send(packet: ServerPacket): void {
        this.game.socket.send(PACKET_ID_PREFIX * (packet.type + 1), packet);
    }

    public handleData(data: [CustomPackets | Packets, ...never[]]): boolean {
        //console.log(JSON.stringify(data));
        let packet = data.shift()!,
            message = this.messages.get(`${packet}`)
                ? this.messages.get(`${packet}`)!()
                : undefined;
        if (message && _.isFunction(message)) {
            let theData = Object.assign([], data);
            return message.call(this, ...theData);
        }
        return false;
    }

    public handleUTF8(message: string): boolean {
        return false;
    }

    public addCustomHandler(type: CustomPackets, callback: ClientPacketHandler) {
        this.messages.set(`${PACKET_ID_PREFIX * (type + 1)}`, () => callback);
    }

    public addOverwriteHandler(type: Packets, callback: ClientPacketHandler) {
        this.messages.set(`${type}`, () => callback);
    }
}
