import { AchievementData } from './../../../../../common/types/achievement.d';
import $ from 'jquery';
import _ from 'lodash-es';

import { Modules } from '@kaetram/common/network';

import Character from '../character';
import Task from './task';
import Skill from './skill';
import Ability from './ability';
import Armour from './equipment/armour';
import Boots from './equipment/boots';
import Pendant from './equipment/pendant';
import Ring from './equipment/ring';
import Weapon from './equipment/weapon';

import { EquipmentData } from '@kaetram/common/types/equipment';
import { PlayerData } from '@kaetram/common/types/player';
import { SkillData } from '@kaetram/common/types/skills';
import { QuestData } from '@kaetram/common/types/quest';
import { AbilityData } from '@kaetram/common/types/ability';

type ExperienceCallback = (
    experience: number,
    prevExperience: number,
    nextExperience: number
) => void;

type AbilityCallback = (key: string, level: number, quickSlot: number) => void;

type PoisonCallback = (status: boolean) => void;

type ManaCallback = (mana: number, maxMana: number) => void;

export default class Player extends Character {
    public rights = 0;
    public wanted = false;

    public pvpKills = -1;
    public pvpDeaths = -1;

    public moveLeft = false;
    public moveRight = false;
    public moveUp = false;
    public moveDown = false;

    public poison = false;
    public disableAction = false;

    public medal: Modules.Medals = Modules.Medals.None;

    public override hitPoints = 0;
    public override maxHitPoints = 0;

    public override mana = 0;
    public override maxMana = 0;

    // Mapping of all equipments to their type.
    public equipments = {
        [Modules.Equipment.Armour]: new Armour(),
        [Modules.Equipment.Boots]: new Boots(),
        [Modules.Equipment.Pendant]: new Pendant(),
        [Modules.Equipment.Ring]: new Ring(),
        [Modules.Equipment.Weapon]: new Weapon()
    };

    public skills: { [key: number]: Skill } = {};
    public abilities: { [key: string]: Ability } = {};
    public quests: { [key: string]: Task } = {};
    public achievements: { [key: string]: Task } = {};

    private syncCallback?: () => void;
    private experienceCallback?: ExperienceCallback;
    private poisonCallback?: PoisonCallback;
    private abilityCallback?: AbilityCallback;
    private manaCallback?: ManaCallback;

    public constructor(instance: string) {
        super(instance, Modules.EntityType.Player);
    }

    /**
     * Loads the player based on the serialzied player
     * data sent from the server.
     * @param data Player data containing essentials.
     * @param sync Whether to sync the player.
     */

    public load(data: PlayerData, sync = false): void {
        this.instance = data.instance;
        this.name = data.name;
        this.level = data.level!;
        this.movementSpeed = data.movementSpeed!;
        this.orientation = data.orientation!;
        this.rights = data.rights!;

        if (data.displayInfo) this.nameColour = data.displayInfo.colour!;

        this.setOrientation(data.orientation);

        if (!sync) this.setGridPosition(data.x, data.y);

        this.setHitPoints(data.hitPoints!, data.maxHitPoints!);

        this.setMana(data.mana!, data.maxMana!);

        if (data.equipments) _.each(data.equipments, this.equip.bind(this));
    }

    /**
     * Loads a batch of skills into the player's skill list.
     * @param skills Contains skill type, experience, and level
     * for each skill we are loading.
     */

    public loadSkills(skills: SkillData[]): void {
        _.each(skills, (skill: SkillData) => this.setSkill(skill));
    }

    /**
     * Loads batch of quest data from the server and inserts
     * it into the list of quests stored for the player.
     * @param quests An array of elements each containing quest info.
     */

    public loadQuests(quests: QuestData[]): void {
        _.each(quests, (quest: QuestData) => {
            this.quests[quest.key] = new Task(
                quest.name!,
                quest.description!,
                quest.stage,
                quest.stageCount!,
                quest.subStage
            );
        });
    }

    /**
     * Parses through the array data containing achievement information
     * and creates an object for each key as well as inserting preliminary data.
     * @param achievements Array containing information about each achievement.
     */

    public loadAchievements(achievements: AchievementData[]): void {
        _.each(achievements, (achievement: AchievementData) => {
            this.achievements[achievement.key] = new Task(
                achievement.name!,
                achievement.description!,
                achievement.stage,
                achievement.stageCount!
            );
        });
    }

    /**
     * Parses through the serialized ability data and creates a new ability object.
     * @param abilities List of abilities received from the server.
     */

    public loadAbilities(abilities: AbilityData[]): void {
        _.each(abilities, (ability: AbilityData) =>
            this.setAbility(ability.key, ability.level, ability.type, ability.quickSlot)
        );
    }

    /**
     * Equips the item based on the equipment type.
     * @param equipment Contains data about the equipment such as
     * type, name, count, ability, etc.
     */

    public equip(equipment: EquipmentData): void {
        let { type, name, key, count, enchantments, ranged, attackStats, defenseStats, bonuses } =
            equipment;

        if (!key) return this.unequip(type);

        this.equipments[type].update(
            key,
            name,
            count,
            enchantments,
            ranged,
            attackStats,
            defenseStats,
            bonuses
        );
    }

    /**
     * Calls an empty update() function onto the equipment slot
     * and resets it.
     * @param type Which equipment slot we are resetting.
     */

    public unequip(type: Modules.Equipment): void {
        this.equipments[type].update();
    }

    /**
     * Signals to the callbacks that the player's data (experience, level, equipment)
     * has undergone a change. This updates the UI essentially.
     */

    public sync(): void {
        this.syncCallback?.();
    }

    /**
     * @returns The key of the currently equipped armour.
     */

    public getSpriteName(): string {
        return this.equipments[Modules.Equipment.Armour].key;
    }

    /**
     * @returns The armour object of the player.
     */

    public getArmour(): Armour {
        return this.equipments[Modules.Equipment.Armour] as Armour;
    }

    /**
     * @returns The boots object of the player.
     */

    public getBoots(): Boots {
        return this.equipments[Modules.Equipment.Boots] as Boots;
    }

    /**
     * @returns The pendant object of the player.
     */

    public getPendant(): Pendant {
        return this.equipments[Modules.Equipment.Pendant] as Pendant;
    }

    /**
     * @returns The ring object of the player.
     */

    public getRing(): Ring {
        return this.equipments[Modules.Equipment.Ring] as Ring;
    }

    /**
     * @returns The weapon object of the player.
     */

    public getWeapon(): Weapon {
        return this.equipments[Modules.Equipment.Weapon] as Weapon;
    }

    /**
     * Adds up the experience from every skill and returns the total.
     * @returns Integer value of the total experience.
     */

    public getTotalExperience(): number {
        let total = 0;

        _.each(this.skills, (skill: Skill) => {
            total += skill.experience;
        });

        return total;
    }

    /**
     * @returns The key of the medal based on the player's medal type.
     */

    public getMedalKey(): string {
        switch (this.medal) {
            case Modules.Medals.Silver:
                return 'silvermedal';

            case Modules.Medals.Gold:
                return 'goldmedal';

            default:
                return '';
        }
    }

    /**
     * Updates the mana of the player.
     * @param mana The current amount of mana.
     * @param maxMana Optional parameter for the max mana.
     */

    public setMana(mana: number, maxMana?: number): void {
        this.mana = mana;

        if (maxMana) this.maxMana = maxMana;

        this.manaCallback?.(this.mana, maxMana || this.maxMana);
    }

    /**
     * Updates the experience of the skill or create a new one first if it doesn't exist.
     * @param arg0 Contains skill data such as type, experience, level, etc.
     */

    public setSkill({ type, experience, level, percentage }: SkillData): void {
        if (!this.skills[type]) this.skills[type] = new Skill(type);

        this.skills[type as Modules.Skills].update(experience, level!, percentage!);
    }

    /**
     * Updates data of the quest based on the key provided with the new stage and
     * substage information.
     * @param key The key of the quest we are updating.
     * @param stage The new stage of the quest.
     * @param subStage The new substage of the quest.
     */

    public setQuest(key: string, stage: number, subStage: number): void {
        this.quests[key]?.update(stage, subStage);
    }

    /**
     * Updates data about an achievement using the provided key.
     * @param key The key of the achievement we are updating.
     * @param stage The new stage of the achievement.
     */

    public setAchievement(key: string, stage: number, name: string): void {
        this.achievements[key]?.update(stage, undefined, name);
    }

    /**
     * Updates an ability's key and level.
     * @param key The key of the ability we are updating.
     * @param level The level of the ability.
     * @param type Optional parameter passed when we are creating a new ability.
     * @param quickSlot The id of the quickslot the ability is in.
     */

    public setAbility(
        key: string,
        level: number,
        type?: Modules.AbilityType,
        quickSlot = -1
    ): void {
        // This function is used when adding abilities for the first time too.
        if (!(key in this.abilities))
            this.abilities[key] = new Ability(type!, key, level, quickSlot);
        else this.abilities[key]?.update(level, quickSlot);

        // If any active ability is detected then we create a callback to display the quick slots.
        if (type === Modules.AbilityType.Active || quickSlot !== -1)
            this.abilityCallback?.(key, level, quickSlot);
    }

    /**
     * Updates the poison status of the player.
     * @param poison Poison status to update with.
     */

    public setPoison(poison: boolean): void {
        this.poison = poison;

        this.poisonCallback?.(poison);
    }

    /**
     * @returns If the weapon the player currently wields is a ranged weapon.
     */

    public isRanged(): boolean {
        return this.equipments[Modules.Equipment.Weapon].ranged;
    }

    /**
     * @returns Whether or not the current weapon's key isn't an empty string.
     */

    public hasWeapon(): boolean {
        return this.equipments[Modules.Equipment.Weapon].exists();
    }

    /**
     * @returns Whether or not the player has a medal.
     */

    public override hasMedal(): boolean {
        return this.medal !== Modules.Medals.None;
    }

    /**
     * @returns Checks whether any of the keyboard directional
     * movement conditionals are true.
     */

    public hasKeyboardMovement(): boolean {
        return this.moveLeft || this.moveRight || this.moveUp || this.moveDown;
    }

    /**
     * Callback for when the player's experience changes.
     * @param callback Contains the experience, previous experience, and next experience.
     */

    public onExperience(callback: ExperienceCallback): void {
        this.experienceCallback = callback;
    }

    /**
     * Callback for when the poison status undergoes a change.
     * @param callback Contains information about the current poison status.
     */

    public onPoison(callback: PoisonCallback): void {
        this.poisonCallback = callback;
    }

    /**
     * Callback for whenever we want to synchronize
     * the player's data to the UI.
     */

    public onSync(callback: () => void): void {
        this.syncCallback = callback;
    }

    /**
     * Callback for when an active ability is added and we signal to the
     * client that we want to display the quick slots menu.
     */

    public onAbility(callback: AbilityCallback): void {
        this.abilityCallback = callback;
    }

    /**
     * Callback for when the player's mana changes.
     * @param callback Contains the current mana and max mana.
     */

    public onMana(callback: ManaCallback): void {
        this.manaCallback = callback;
    }
}
