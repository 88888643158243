import MenuController from '@kaetram/client/src/controllers/menu';

import type Game from '@kaetram/client/src/game';
import { SPIRITS_MAX } from '@kaetram/common/extensions/sot/src/constants';
import { Modules } from '@kaetram/common/network';
import { CustomPackets } from '@kaetram/common/extensions/sot/src/enums';
import Spirits from '@kaetram/client/extensions/sot/src/menu/spirits';
import TerraGame from '@kaetram/client/extensions/sot/src/terragame';
import Actions from '@kaetram/client/src/menu/actions';

export default class SotMenuController {
    private actions: Actions = new Actions();
    public spirits!: Spirits;

    public constructor(
        public terraGame: TerraGame,
        public game: Game,
        public menu: MenuController
    ) {
        this.loadSpirits(SPIRITS_MAX);
    }

    /**
     * This can be called multiple times and can be used
     * to completely refresh the spirits overview.
     */
    public loadSpirits(size: number): void {
        this.spirits = new Spirits(this.terraGame, this, this.actions, size);
        this.menu.add(this.spirits);
    }

    handleAction(selectedSlot: number, action: Modules.MenuActions) {
        switch (action) {
            case Modules.MenuActions.BindSoul:
                this.terraGame.messages.send({
                    type: CustomPackets.SoulBind,
                    containerType: Modules.ContainerType.Inventory,
                    containerIndex: selectedSlot
                });
                return true;
            case Modules.MenuActions.EditSpiritCore:
                this.terraGame.messages.send({
                    type: CustomPackets.EditSpiritCore,
                    containerType: Modules.ContainerType.Spirits,
                    containerIndex: selectedSlot
                });
                this.menu.hide();
                return true;
            case Modules.MenuActions.ManifestSpiritCore:
                this.terraGame.messages.send({
                    type: CustomPackets.ManifestConstruct,
                    containerType: Modules.ContainerType.Spirits,
                    containerIndex: selectedSlot
                });
                this.menu.hide();
                return true;
        }
        return false;
    }
}
