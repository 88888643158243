import { CustomPackets } from '@kaetram/common/extensions/sot/src/enums';
import TerraGame from '../../terragame';
import { ShowCodingPacket } from '@kaetram/common/extensions/sot/types/messages/toclient';

export default class Coding {
    private messages;

    public constructor(private game: TerraGame) {
        let { messages } = game;
        this.messages = messages;
        this.messages.addCustomHandler(CustomPackets.ShowCoding, this.showCoding.bind(this));
    }

    private showCoding(data: ShowCodingPacket): boolean {
        this.game.showWith(data);
        return true;
    }
}
