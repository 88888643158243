import Packets from '@kaetram/common/network/packets';
import { ContainerPacket, TeleportPacket } from '@kaetram/common/types/messages/outgoing';
import { Modules, Opcodes } from '@kaetram/common/network';
import TerraGame from '../../terragame';
import Player from '@kaetram/client/src/entity/character/player/player';
import Construct from '@kaetram/client/extensions/sot/src/entity/character/construct/construct';
import Character from '@kaetram/client/src/entity/character/character';

export default class Overwrites {
    private messages;

    public constructor(private game: TerraGame) {
        let { messages } = game;
        this.messages = messages;
        this.messages.addOverwriteHandler(Packets.Container, this.handleContainer.bind(this));
        this.messages.addOverwriteHandler(Packets.Teleport, this.handleTeleport.bind(this));
    }

    private handleContainer(opcode: Opcodes.Container, info: ContainerPacket) {
        if (info.type !== Modules.ContainerType.Spirits) return false;

        let container = this.game.sotMenu?.spirits;
        switch (opcode) {
            case Opcodes.Container.Batch: {
                let { data } = info;
                container?.batch(data!.slots);
                break;
            }

            case Opcodes.Container.Add: {
                let { slot } = info;
                container?.add(slot!);
                break;
            }
        }
        return true;
    }

    private handleTeleport(info: TeleportPacket) {
        let character = this.game.game.entities.get<Character>(info?.instance);
        if (!character?.isConstruct()) return false;
        this.game.game.teleport(character, info.x!, info.y!);
        return true;
    }
}
