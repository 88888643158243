import type App from '@kaetram/client/src/app';
import type Game from '@kaetram/client/src/game';
import Blockly from 'blockly';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DarkTheme from '@blockly/theme-dark';

import $ from 'jquery';
import Messages from './network/messages';
import Coding from './network/handlers/coding';
import Overwrites from './network/handlers/overwrites';
import { ShowCodingPacket } from '@kaetram/common/extensions/sot/types/messages/toclient';
import { BehaviourType, CustomPackets } from '@kaetram/common/extensions/sot/src/enums';
import SotMenuController from './menu/menu';
import { ContainerSlotPointer } from '@kaetram/common/extensions/sot/types/interfaces';
import { initAndGetToolboxJson } from '@kaetram/common/extensions/sot/src/blockly/blocks';
import Input from '@kaetram/client/src/controllers/input';
import Constructs from '@kaetram/client/extensions/sot/src/network/handlers/constructs';
import SotHeader from '@kaetram/client/extensions/sot/src/menu/header';
import Construct from '@kaetram/client/extensions/sot/src/entity/character/construct/construct';

export default class TerraGame {
    private close!: JQuery<HTMLElement>;
    private save!: JQuery<HTMLElement>;
    private codingArea!: JQuery<HTMLElement>;
    public messages!: Messages;
    public workspace: Blockly.Workspace;
    private onresize!: () => void;

    private codingHandler!: Coding;
    private constructsHandler!: Constructs;
    private overwritesHandler!: Overwrites;
    private activeSpiritSlot!: ContainerSlotPointer;

    public sotMenu: SotMenuController;

    private manifestedConstruct: Construct | undefined;
    private sotHeader: SotHeader;

    public constructor(public app: App, public game: Game) {
        this.messages = new Messages(app, game);
        this.loadBlockly();
        this.sotMenu = new SotMenuController(this, this.game, this.game.menu);
        this.sotHeader = new SotHeader(this);
    }

    public connect() {
        this.codingHandler = new Coding(this);
        this.constructsHandler = new Constructs(this);
        this.overwritesHandler = new Overwrites(this);
    }

    public handleDisconnection(noError = false): void {}

    private loadBlockly(): void {
        let transparentDarkTheme = Blockly.Theme.defineTheme('transparentDarkTheme', {
                base: DarkTheme,
                componentStyles: {
                    workspaceBackgroundColour: 'rgba(30,30,30,0.73)',
                    toolboxBackgroundColour: 'rgba(30,30,30,0.73)'
                }
            }),
            blocklyArea = document.querySelector<HTMLElement>('#coding-area'),
            blocklyDiv = document.querySelector<HTMLElement>('#coding-ui');

        this.workspace = Blockly.inject(blocklyDiv, {
            theme: transparentDarkTheme,
            media: 'https://unpkg.com/blockly/media/',
            toolbox: initAndGetToolboxJson([]),
            move: {
                scrollbars: {
                    horizontal: true,
                    vertical: true
                },
                drag: true,
                wheel: true
            },
            grid: {
                spacing: 30,
                length: 3,
                colour: '#555',
                snap: true
            },
            maxInstances: {
                whenHit: 1,
                targetItem: 1,
                targetMob: 1,
                targetConstruct: 1,
                targetNPC: 1,
                targetPlayer: 1,
                targetSelf: 1,
                targetUsableObject: 1
            },
            trashcan: true
        });
        // TODO make maxInstances dynamic

        this.onresize = () => {
            // Compute the absolute coordinates and dimensions of blocklyArea.
            if (blocklyArea && blocklyDiv) {
                let element = blocklyArea,
                    x = 0,
                    y = 0;
                do {
                    x += element.offsetLeft;
                    y += element.offsetTop;
                    element = <HTMLElement>element.offsetParent;
                } while (element);
                // Position blocklyDiv over blocklyArea.
                blocklyDiv.style.left = `${x}px`;
                blocklyDiv.style.top = `${y}px`;
                blocklyDiv.style.width = `${blocklyArea.offsetWidth}px`;
                blocklyDiv.style.height = `${blocklyArea.offsetHeight}px`;
                Blockly.svgResize(this.workspace);
                this.workspace.getFlyout().setVisible(false);
                this.workspace.getFlyout().setVisible(true);
                this.workspace.setVisible(false);
                this.workspace.setVisible(true);

                console.log('resize');
                //console.log(JSON.stringify(Blockly.serialization.workspaces.save(this.workspace)));
            }
        };

        window.addEventListener('resize', this.onresize, false);
        this.onresize();
        Blockly.svgResize(this.workspace);

        this.codingArea = $('#coding-area');
        this.hide();
        this.close = $('#close-coding-ui');
        this.close.on('click', () => this.hide());

        this.save = $('#save-coding-ui');
        this.save.on('click', () => this.saveCoding());
    }

    public hide() {
        this.codingArea.hide();
    }

    public showWith(data: ShowCodingPacket) {
        this.activeSpiritSlot = {
            containerIndex: data.containerIndex,
            containerType: data.containerType
        };
        let json = JSON.parse(data.blocklyJson);
        this.configureToolbox(data.unlockedBehaviours);
        Blockly.serialization.workspaces.load(json, this.workspace);
        this.game.menu.hide();
        this.codingArea.show();
        this.onresize();
    }

    private saveCoding() {
        let code = JSON.stringify(Blockly.serialization.workspaces.save(this.workspace));

        this.messages.send({
            type: CustomPackets.SaveCoding,
            blocklyJson: code,
            ...this.activeSpiritSlot
        });
        this.hide();

        this.sotMenu.spirits.show();
    }

    private configureToolbox(unlockedBehaviours: BehaviourType[]) {
        this.workspace.updateToolbox(initAndGetToolboxJson(unlockedBehaviours));
    }

    public onMove(inputController: Input, position: Position) {
        // Handle entity interaction.
        let newEntity = this.game.getEntityAt(position.x, position.y),
            isSameEntity = newEntity === inputController.entity,
            isItemEntity = newEntity?.isItem();
        if (!isSameEntity && newEntity && this.game.terraGame.getManifestedConstruct()) {
            inputController.entity = newEntity;

            // Visually select the entity
            inputController.selectedCellVisible = true;
            inputController.selectedX = newEntity?.gridX || -1;
            inputController.selectedY = newEntity?.gridY || -1;
            inputController.targetAnimation.setRow(2);

            this.messages.send({
                type: CustomPackets.SetConstructTarget,
                target: newEntity.instance
            });
            return true;
        }
        return false;
    }

    removeManifestedConstruct() {
        this.manifestedConstruct = undefined;
        this.sotHeader.relink();
    }
    setManifestedConstruct(construct: Construct) {
        this.manifestedConstruct = construct;
        this.sotHeader.relink(construct);
    }

    getManifestedConstruct() {
        return this.manifestedConstruct;
    }

    resize() {
        this.sotHeader.resize();
    }
}
